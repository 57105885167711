@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.App {
  overflow-y: scroll;
  height: 100vh;
  font-family: 'Rubik', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; /* Added to ensure padding is included in the width */
}

#section-1 {
  color: #435334;
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

#section-2 {
  color: #435334;
  background-color: #cedebd;
  font-size: 20px;
}

#section-3 {
  background-color: #9eb384;
  color: #faf1e4;
  padding: 20px;
  box-sizing: border-box;
}

#section-4 {
  background-color: #9eb384;
  color: #faf1e4;
}

.css-selector {
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

#center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-title {
  font-size: 70px; /* Adjusted the font size */
  font-weight: 600;
  margin-bottom: -15px;
  text-align: center;
}

.sub-title {
  font-size: 31px;
  text-align: center;
}

.navbar {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

.mobile-navbar {
  display: flex;
  justify-content: flex-start; /* Changed alignment to left */
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  background: rgba(250, 241, 228, 0.8);
}

.mobile-navbar .fa-bars,
.mobile-navbar .fa-times {
  font-size: 24px;
  cursor: pointer;
  color: #435334;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Changed alignment to left */
  position: absolute;
  top: 50px;
  left: 10px; /* Changed position to left */
  background: rgba(250, 241, 228, 0.9);
  border-radius: 8px;
  padding: 10px 20px;
}

.mobile-menu a {
  color: black;
  text-decoration: none;
  padding: 10px 0;
  text-align: left; /* Changed alignment to left */
}

.column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.experiences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experience-title {
  font-size: 35px;
  text-align: center;
}

.experience-content {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
}

.experience-header {
  margin-bottom: -10px;
  text-align: left;
}

.download-button {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Stack the elements vertically */
  align-items: center; /* Center-align the items */
  background-color: #faf1e4;
  border-radius: 15px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.download-button p {
  margin: 0 0 10px; /* Adds margin to separate the text from the button */
  text-align: center; /* Center-align the text */
}

.download-button button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2); /* Adding a border with transparency */
  background: rgba(60, 111, 2, 0.3); /* Less transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  color: #faf1e4;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  width: 100%; /* Ensures the button takes full width */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.ContactPage {
  background-color: #faf1e4;
  color: #9eb384;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  padding: 35px;
  width: 100%; /* Added to ensure it fits within the screen */
  box-sizing: border-box; /* Added to ensure padding is included in width */
}

.download-button button:hover {
  background: rgba(158, 179, 132, 0.5); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBox {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border with transparency */
  border-radius: 10px;
  color: #faf1e4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background: rgba(250, 241, 228, 0.1); /* Slightly tinted background */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px 20px; /* Padding for better appearance */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.skillBox:hover {
  background: rgba(250, 241, 228, 0.3); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBoxDiv {
  display: flex;
  justify-content: left;
  align-items: space-between;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.progress-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  width: 0%;
  transition: width 0.3s;
}

.sideWayWord {
  transform: rotate(-90deg);
  display: inline-block;
  white-space: nowrap;
  font-size: 45px;
  text-decoration: underline;
  margin-right: 20px;
}

.whiteBoxContent {
  background-color: #faf1e4;
  border-radius: 10px;
  margin-left: auto;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%; /* Ensures the container takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.textarea-container textarea {
  width: 100%; /* Ensures the textarea takes full width */
  height: 150px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  resize: none;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

.textarea-container .char-limit {
  align-self: flex-end;
  font-size: 12px;
  color: #b1b1b1;
  margin-top: 5px;
}

.buttonSubmit {
  background-color: #9eb384;
  color: white;
  width: 100%; /* Ensures the button takes full width */
  height: 45px;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
}

.icon-boxes {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.iconsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  margin-top: 30px;
  width: 100%; /* Ensures the div takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .App {
    scroll-snap-type: none;
  }

  .App-section {
    height: auto;
    min-height: 100vh;
  }

  #section-2 .column img {
    display: none;
  }

  .experience-title {
    font-size: 20px;
  }

  .experience-content {
    font-size: 12px;
    width: 100%;
  }

  .experience-header {
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (max-width: 480px) {
  .main-title {
    font-size: 50px;
  }

  .sub-title {
    font-size: 20px;
  }

  .section-title {
    font-size: 25px;
  }

  .section-content {
    font-size: 16px;
  }

  .textarea-container textarea {
    width: 100%;
    height: 100px;
  }

  .buttonSubmit {
    width: 100%;
  }
}

/* Your existing CSS */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

/* Mobile-specific styles */
@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-navbar {
    display: none;
  }
}

/* Your existing CSS */

.App {
  font-family: 'Rubik', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section-1 {
  color: #435334;
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

#section-2 {
  color: #435334;
  background-color: #cedebd;
  font-size: 20px;
}

#section-3 {
  background-color: #9eb384;
  color: #faf1e4;
}

#section-4 {
  background-color: #9eb384;
  color: #faf1e4;
}

.css-selector {
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

#center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

.column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.experiences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-button {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Stack the elements vertically */
  align-items: center; /* Center-align the items */
  background-color: #faf1e4;
  border-radius: 15px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.download-button p {
  margin: 0 0 10px; /* Adds margin to separate the text from the button */
  text-align: center; /* Center-align the text */
}

.download-button button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2); /* Adding a border with transparency */
  background: rgba(60, 111, 2, 0.3); /* Less transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  color: #faf1e4;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  width: 100%; /* Ensures the button takes full width */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.ContactPage {
  background-color: #faf1e4;
  color: #9eb384;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  padding: 35px;
  width: 100%; /* Added to ensure it fits within the screen */
  box-sizing: border-box; /* Added to ensure padding is included in width */
}

.download-button button:hover {
  background: rgba(158, 179, 132, 0.5); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBox {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border with transparency */
  border-radius: 10px;
  color: #faf1e4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background: rgba(250, 241, 228, 0.1); /* Slightly tinted background */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px 20px; /* Padding for better appearance */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.skillBox:hover {
  background: rgba(250, 241, 228, 0.3); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBoxDiv {
  display: flex;
  justify-content: left;
  align-items: space-between;
  flex-direction: row;
  gap: 10px;
}

.progress-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  width: 0%;
  transition: width 0.3s;
}

.sideWayWord {
  transform: rotate(-90deg);
  display: inline-block;
  white-space: nowrap;
  font-size: 45px;
  text-decoration: underline;
  margin-right: 20px;
}

.whiteBoxContent {
  background-color: #faf1e4;
  border-radius: 10px;
  margin-left: auto;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%; /* Ensures the container takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.textarea-container textarea {
  width: 100%; /* Ensures the textarea takes full width */
  height: 150px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  resize: none;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

.textarea-container .char-limit {
  align-self: flex-end;
  font-size: 12px;
  color: #b1b1b1;
  margin-top: 5px;
}

.buttonSubmit {
  background-color: #9eb384;
  color: white;
  width: 100%; /* Ensures the button takes full width */
  height: 45px;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
}

.icon-boxes {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.iconsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  margin-top: 30px;
  width: 100%; /* Ensures the div takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .App {
    scroll-snap-type: none;
  }

  .App-section {
    height: auto;
    min-height: 100vh;
  }

  #section-2 .column img {
    display: none;
  }

  .experience-title {
    font-size: 20px;
  }

  .experience-content {
    font-size: 12px;
    width: 100%;
  }

  .experience-header {
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (max-width: 480px) {
  .main-title {
    font-size: 50px;
  }

  .sub-title {
    font-size: 20px;
  }

  .section-title {
    font-size: 25px;
  }

  .section-content {
    font-size: 16px;
  }

  .textarea-container textarea {
    width: 100%;
    height: 100px;
  }

  .buttonSubmit {
    width: 100%;
  }
}

/* Your existing CSS */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

/* Mobile-specific styles */
@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-navbar {
    display: none;
  }
}

/* Your existing CSS */

.App {
  font-family: 'Rubik', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section-1 {
  color: #435334;
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

#section-2 {
  color: #435334;
  background-color: #cedebd;
  font-size: 20px;
}

#section-3 {
  background-color: #9eb384;
  color: #faf1e4;
}

#section-4 {
  background-color: #9eb384;
  color: #faf1e4;
}

.css-selector {
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

#center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

.column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.experiences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-button {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Stack the elements vertically */
  align-items: center; /* Center-align the items */
  background-color: #faf1e4;
  border-radius: 15px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.download-button p {
  margin: 0 0 10px; /* Adds margin to separate the text from the button */
  text-align: center; /* Center-align the text */
}

.download-button button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2); /* Adding a border with transparency */
  background: rgba(60, 111, 2, 0.3); /* Less transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  color: #faf1e4;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  width: 100%; /* Ensures the button takes full width */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.ContactPage {
  background-color: #faf1e4;
  color: #9eb384;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  padding: 35px;
  width: 100%; /* Added to ensure it fits within the screen */
  box-sizing: border-box; /* Added to ensure padding is included in width */
}

.download-button button:hover {
  background: rgba(158, 179, 132, 0.5); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBox {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border with transparency */
  border-radius: 10px;
  color: #faf1e4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background: rgba(250, 241, 228, 0.1); /* Slightly tinted background */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px 20px; /* Padding for better appearance */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.skillBox:hover {
  background: rgba(250, 241, 228, 0.3); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBoxDiv {
  display: flex;
  justify-content: left;
  align-items: space-between;
  flex-direction: row;
  gap: 10px;
}

.progress-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  width: 0%;
  transition: width 0.3s;
}

.sideWayWord {
  transform: rotate(-90deg);
  display: inline-block;
  white-space: nowrap;
  font-size: 45px;
  text-decoration: underline;
  margin-right: 20px;
}

.whiteBoxContent {
  background-color: #faf1e4;
  border-radius: 10px;
  margin-left: auto;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%; /* Ensures the container takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.textarea-container textarea {
  width: 100%; /* Ensures the textarea takes full width */
  height: 150px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  resize: none;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

.textarea-container .char-limit {
  align-self: flex-end;
  font-size: 12px;
  color: #b1b1b1;
  margin-top: 5px;
}

.buttonSubmit {
  background-color: #9eb384;
  color: white;
  width: 100%; /* Ensures the button takes full width */
  height: 45px;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
}

.icon-boxes {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.iconsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  margin-top: 30px;
  width: 100%; /* Ensures the div takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .App {
    scroll-snap-type: none;
  }

  .App-section {
    height: auto;
    min-height: 100vh;
  }

  #section-2 .column img {
    display: none;
  }

  .experience-title {
    font-size: 20px;
  }

  .experience-content {
    font-size: 12px;
    width: 100%;
  }

  .experience-header {
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (max-width: 480px) {
  .main-title {
    font-size: 50px;
  }

  .sub-title {
    font-size: 20px;
  }

  .section-title {
    font-size: 25px;
  }

  .section-content {
    font-size: 16px;
  }

  .textarea-container textarea {
    width: 100%;
    height: 100px;
  }

  .buttonSubmit {
    width: 100%;
  }
}

/* Your existing CSS */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

/* Mobile-specific styles */
@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-navbar {
    display: none;
  }
}

/* Your existing CSS */

.App {
  font-family: 'Rubik', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section-1 {
  color: #435334;
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

#section-2 {
  color: #435334;
  background-color: #cedebd;
  font-size: 20px;
}

#section-3 {
  background-color: #9eb384;
  color: #faf1e4;
}

#section-4 {
  background-color: #9eb384;
  color: #faf1e4;
}

.css-selector {
  background: linear-gradient(235deg, #faf1e4, #cedebd, #9eb384, #435334);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

#center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 15px;
}

.navbar a.active {
  border-radius: 8px;
  font-weight: 700;
  color: #2d3822;
}

.column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.experiences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-button {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Stack the elements vertically */
  align-items: center; /* Center-align the items */
  background-color: #faf1e4;
  border-radius: 15px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.download-button p {
  margin: 0 0 10px; /* Adds margin to separate the text from the button */
  text-align: center; /* Center-align the text */
}

.download-button button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2); /* Adding a border with transparency */
  background: rgba(60, 111, 2, 0.3); /* Less transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  color: #faf1e4;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  width: 100%; /* Ensures the button takes full width */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.ContactPage {
  background-color: #faf1e4;
  color: #9eb384;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  padding: 35px;
  width: 100%; /* Added to ensure it fits within the screen */
  box-sizing: border-box; /* Added to ensure padding is included in width */
}

.download-button button:hover {
  background: rgba(158, 179, 132, 0.5); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBox {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border with transparency */
  border-radius: 10px;
  color: #faf1e4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background: rgba(250, 241, 228, 0.1); /* Slightly tinted background */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px 20px; /* Padding for better appearance */
  transition: background 0.3s ease, transform 0.3s ease; /* Transition effect */
}

.skillBox:hover {
  background: rgba(250, 241, 228, 0.3); /* More opaque background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.skillBoxDiv {
  display: flex;
  justify-content: left;
  align-items: space-between;
  flex-direction: row;
  gap: 10px;
}

.progress-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  width: 0%;
  transition: width 0.3s;
}

.sideWayWord {
  transform: rotate(-90deg);
  display: inline-block;
  white-space: nowrap;
  font-size: 45px;
  text-decoration: underline;
  margin-right: 20px;
}

.whiteBoxContent {
  background-color: #faf1e4;
  border-radius: 10px;
  margin-left: auto;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%; /* Ensures the container takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.textarea-container textarea {
  width: 100%; /* Ensures the textarea takes full width */
  height: 150px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  resize: none;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

.textarea-container .char-limit {
  align-self: flex-end;
  font-size: 12px;
  color: #b1b1b1;
  margin-top: 5px;
}

.buttonSubmit {
  background-color: #9eb384;
  color: white;
  width: 100%; /* Ensures the button takes full width */
  height: 45px;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
}

.icon-boxes {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.iconsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  margin-top: 30px;
  width: 100%; /* Ensures the div takes full width */
  box-sizing: border-box; /* Ensures padding is included in width */
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .App {
    scroll-snap-type: none;
  }

  .App-section {
    height: auto;
    min-height: 100vh;
  }

  #section-2 .column img {
    display: none;
  }

  .experience-title {
    font-size: 20px;
  }

  .experience-content {
    font-size: 12px;
    width: 100%;
  }

  .experience-header {
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-navbar {
    display: flex;
  }
}

@media only screen and (max-width: 480px) {
  .main-title {
    font-size: 50px;
  }

  .sub-title {
    font-size: 20px;
  }

  .section-title {
    font-size: 25px;
  }

  .section-content {
    font-size: 16px;
  }

  .textarea-container textarea {
    width: 100%;
    height: 100px;
  }

  .buttonSubmit {
    width: 100%;
  }
}
